/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Form from '../Shared/Form';
import { FREE_TRIAL_CLEAR_ROLE, FREE_TRIAL_FINISH, FREE_TRIAL_SELECT } from './constants';

const IMPACT_PRODUCT_CODE = 'EN00IMW2TCL001';
const isImpact = (code) => code === IMPACT_PRODUCT_CODE;

const FreeTrialForm = ({ t, selected, onSelectOccupation, onFinish, ...props }) => {
  const onOccupationSelect = (occupation) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'UserAction',
        formName: 'TrialForm',
        formType: 'TrialForm',
        formStep: 'Step3',
        using_as: occupation,
      });
    }

    onSelectOccupation(occupation);
  };

  const onContinueFinish = () => {
    if (window.dataLayer) {
      const subscriptionInfo = JSON.parse(localStorage.getItem('subscriptionInfo') || '[]');
      // eslint-disable-next-line no-unused-vars
      const { product_of_interest, platform_of_interest, os, using_as, industry, ...otherSelected } = selected;
      const dataLayerInfo = {
        isImpactOrder: 'no',
        Impact_transaction_id: '',
        transaction_id: '',
      };
      subscriptionInfo.forEach(({ orderNumber, code }) => {
        if (isImpact(code)) {
          dataLayerInfo.isImpactOrder = 'yes';
          if (subscriptionInfo.length > 1) {
            dataLayerInfo.Impact_transaction_id = orderNumber;
            return;
          }
        }
        dataLayerInfo.transaction_id = orderNumber;
      });

      window.dataLayer.push({
        event: 'UserAction',
        formName: 'TrialForm',
        formType: 'TrialForm',
        formStep: 'form_submitted',
        ...dataLayerInfo,
        ...industry,
        ...otherSelected,
      });
    }
    onFinish();
  };

  return (
    <Form
      {...props}
      selected={selected}
      onOccupationSelect={onOccupationSelect}
      onContinueFinish={onContinueFinish}
      buttonLabel={t('Start your free trial')}
    />
  );
};

export default connect(
  (state) => ({
    selected: state.freeTrial.selected,
  }),
  (dispatch) => ({
    onFormChange: (data) => dispatch({ type: FREE_TRIAL_SELECT, data }),
    onSelectOccupation: (occupation) => dispatch({ type: FREE_TRIAL_SELECT, data: { using_as: occupation } }),
    onBack: () => dispatch({ type: FREE_TRIAL_CLEAR_ROLE }),
    onFinish: () => {
      dispatch({ type: FREE_TRIAL_FINISH });
    },
  }),
)(withTranslation()(FreeTrialForm));
