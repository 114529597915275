import { call, put, select, cancel } from 'redux-saga/effects';
import i18n from 'i18next';
import Api from '../Utils/Api';
import { PRODUCTS } from '../FreeTrial/constants';
import { LangCountries, LanguageCodes } from '../Utils/Countries';
import { LANGUAGE_COOKIE, getCookie } from '../Utils/cookiesHelper';

function* getSession() {
  let sessionData = null;
  try {
    sessionData = yield call(Api.fetch, '/session', {
      host: window.SESSION_INFO_URL,
    });

    yield put({ type: 'GET_SESSION#COMPLETE', data: { ...sessionData } });
  } catch (error) {
    yield put({ type: 'GET_SESSION#ERROR', error });
  }

  return sessionData;
}

function getLanguage(location) {
  let language = 'en';

  // check location
  if (LangCountries[location]) {
    language = LangCountries[location];
  }

  // check cg-website cookie
  const cookieLanguage = getCookie(LANGUAGE_COOKIE);
  if (LanguageCodes.includes(cookieLanguage)) {
    language = cookieLanguage;
  }

  if (!window.LOCALES_ENABLED.includes(language)) {
    language = 'en';
  }

  i18n.changeLanguage(language);
}

export function* init() {
  const sessionData = yield call(getSession);
  if (!sessionData.UserGUID) {
    const routing = yield select((state) => state.routing);
    const product = (routing.params && routing.params.product) || 'free-trial';
    const productCode = PRODUCTS[product].code;

    const newFlowDomains = [window.domains.chaos, window.domains.enscape];
    // Exclude beta routes from the new flow
    if (newFlowDomains.includes(window.location.hostname) && !window.location.href.includes('beta')) {
      window.location = `${
        window.ACCOUNTS_URL
      }/trial/email-check?productCode=${productCode}&return_to=${encodeURIComponent(window.location)}`;
      yield cancel();
    }

    const isLegacyLogin = window.location.hostname === window.domains.corona;
    const returnTo = encodeURIComponent(window.location);
    window.location = `${window.ACCOUNTS_URL}${isLegacyLogin ? '' : '/service_login'}?return_to=${returnTo}`;
    yield cancel();
  }

  window.Verisoul.account({
    id: sessionData.UserGUID,
    email: sessionData.Email,
  });

  yield call(getLanguage, sessionData.Location);
}
