import { call, put, fork, select, takeLatest } from 'redux-saga/effects';
import { navigate } from 'redux-saga-first-router';

import Api from '../Utils/Api';
import {
  MAYA_PLE_CREATE,
  MAYA_PLE_SET_FORM_TYPE,
  MAYA_PLE_ERROR,
  MAYA_PLE_SET_LOADING,
  MAYA_PLE_RENEW,
  MAYA_PLE_SELECT,
  MAYA_PLE_GET_DETAILS,
  MAYA_PLE_SET_STEP,
} from './constants';

export function* navigateMayaPleTrial() {
  yield fork(watchCreate);
  yield fork(watchRenew);
  yield fork(watchGetDetails);

  yield put({ type: MAYA_PLE_SET_LOADING, loading: true });

  const routing = yield select((state) => state.routing);

  const { is_eligible, used_count } = yield call(Api.fetch, '/ple/info');

  if (!is_eligible) {
    yield put(
      navigate('MAYA_PLE_WELCOME_BACK', {
        lang: routing.params.lang,
      }),
    );
    return;
  }

  if (used_count === 0) {
    yield put({ type: MAYA_PLE_SET_FORM_TYPE, formType: 'initial' });
  }

  if (used_count === 1) {
    yield put({ type: MAYA_PLE_SET_FORM_TYPE, formType: 'firstRenewal' });
  }

  if (used_count > 1) {
    yield put({ type: MAYA_PLE_SET_FORM_TYPE, formType: 'renewal' });
  }

  yield put({ type: MAYA_PLE_SET_LOADING, loading: false });
}

export function* navigateNotEligible() {
  const routing = yield select((state) => state.routing);
  const { is_eligible } = yield call(Api.fetch, '/ple/info');
  if (is_eligible) {
    yield put(
      navigate('MAYA_PLE', {
        lang: routing.params.lang,
      }),
    );
  }
}

function* watchGetDetails() {
  yield takeLatest(MAYA_PLE_GET_DETAILS, getDetails);
}

function* getDetails() {
  yield put({ type: MAYA_PLE_SET_LOADING, loading: true });
  try {
    const details = yield call(Api.fetch, '/ple/details');
    const filledDetails = Object.fromEntries(
      Object.entries(details).filter(([field, value]) => {
        if (field === 'industry') {
          return Boolean(value.name) && Boolean(value.role);
        }

        if (field === 'feedback' || field === 'share_your_work') {
          return false;
        }

        return value;
      }),
    );
    yield put({
      type: MAYA_PLE_SELECT,
      data: filledDetails,
    });
  } catch (error) {
    yield put({ type: MAYA_PLE_ERROR, error });
    window.scrollTo(0, 0);
  }
  yield put({ type: MAYA_PLE_SET_LOADING, loading: false });
}

function* watchCreate() {
  yield takeLatest(MAYA_PLE_CREATE, onCreate);
}

function* onCreate() {
  yield put({ type: MAYA_PLE_SET_LOADING, loading: true });
  const routing = yield select((state) => state.routing);
  const { selected } = yield select((state) => state.mayaPle);
  const { dialCode, phoneNumber, ...otherSelected } = selected;

  const body = {
    ...otherSelected,
    phone_number: phoneNumber ? `${dialCode}${phoneNumber}` : '',
  };

  try {
    yield call(Api.fetch, '/ple/create', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      on5xx: 'throw',
      onUnauthorized: 'throw',
      parseJSON: false,
      body: JSON.stringify(body),
    });

    yield put(
      navigate('MAYA_PLE_SUCCESS', {
        lang: routing.params.lang,
      }),
    );
  } catch (error) {
    yield put({ type: MAYA_PLE_ERROR, error });
    window.scrollTo(0, 0);
  }
  yield put({ type: MAYA_PLE_SET_STEP, step: 2 });
  yield put({ type: MAYA_PLE_SET_LOADING, loading: false });
}

function* watchRenew() {
  yield takeLatest(MAYA_PLE_RENEW, onRenew);
}

function* onRenew() {
  yield put({ type: MAYA_PLE_SET_LOADING, loading: true });
  const routing = yield select((state) => state.routing);
  const { selected } = yield select((state) => state.mayaPle);
  const { dialCode, phoneNumber, ...otherSelected } = selected;

  const body = {
    ...otherSelected,
    phone_number: phoneNumber ? `${dialCode}${phoneNumber}` : '',
  };

  try {
    yield call(Api.fetch, '/ple/renew', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      on5xx: 'throw',
      onUnauthorized: 'throw',
      parseJSON: false,
      body: JSON.stringify(body),
    });

    yield put(
      navigate('MAYA_PLE_SUCCESS', {
        lang: routing.params.lang,
      }),
    );
  } catch (error) {
    yield put({ type: MAYA_PLE_ERROR, error });
    window.scrollTo(0, 0);
  }
  yield put({ type: MAYA_PLE_SET_STEP, step: 2 });
  yield put({ type: MAYA_PLE_SET_LOADING, loading: false });
}
